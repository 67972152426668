import { render, staticRenderFns } from "./RouterNav.vue?vue&type=template&id=275f6c24&scoped=true"
import script from "./RouterNav.vue?vue&type=script&lang=js"
export * from "./RouterNav.vue?vue&type=script&lang=js"
import style0 from "./RouterNav.vue?vue&type=style&index=0&id=275f6c24&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "275f6c24",
  null
  
)

export default component.exports