<template>
  <div class="choose-time">
    <div class="select-time" :style="backGroundStyle">
      <div class="range-placeholder" :style="placeholderStyle" :class="{ range:startTime && endTime }"
           @click="selectTime">
        {{ startTime || '选择开始时间' }}
      </div>
      <div class="range-separator">至</div>
      <div class="range-placeholder" :style="placeholderStyle" :class="{ range:startTime && endTime}"
           @click="selectTime">
        {{ endTime || '选择截止时间' }}
      </div>
    </div>

    <el-date-picker
        v-model="diyTime"
        @change="formatDiy"
        ref="date"
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        class="visible-date date"
        type="date"
        :picker-options="diyOptions"
    >
    </el-date-picker>
    <el-date-picker
        v-model="diyTime"
        @change="formatDiy"
        ref="diy"
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        class="visible-date diy"
        type="daterange"
        :picker-options="diyOptions"
    >
    </el-date-picker>

    <el-date-picker
        v-model="weekTime"
        @change="formatWeek"
        ref="week"
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        class="visible-date week"
        type="week"
        :picker-options="weekOptions"
    >
    </el-date-picker>

    <el-date-picker
        v-model="monthTime"
        ref="month"
        value-format="yyyy-MM-dd"
        format="yyyy-MM-dd"
        class="visible-date month"
        type="month"
        @change="formatMonth"
        :picker-options="monthOptions"
    >
    </el-date-picker>
  </div>
</template>

<script>
// 选择时间

export default {
  name: "ChooseTime",
  props: {
    dateType: {
      type: Number,
      require: true,
      default: () => 0
    },
    placeholderStyle: {
      type: String,
      require: false,
      default: () => ''
    },
    backGroundStyle: {
      type: String,
      require: false,
      default: () => ''
    }
  },
  watch: {
    dateType(val, old) {
      if (val !== old) {
        switch (val) {
          case 2:
            this.startTime = this.common.formatDate('yyyy-MM-dd', new Date(new Date().getFullYear(), new Date().getMonth(), 1))
            this.endTime = this.common.formatDate('yyyy-MM-dd', new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))
            this.$emit('chooseTime', this.startTime, this.endTime)
            break
          case  1:
            this.startTime = this.common.formatDate('yyyy-MM-dd', new Date(new Date().setDate(new Date(new Date()).getDate() - 1)))
            this.endTime = this.common.formatDate('yyyy-MM-dd', new Date(new Date().setDate(new Date(new Date()).getDate() + 5)))
            this.$emit('chooseTime', this.startTime, this.endTime)
            break
        }
      }
    }
  },
  data() {
    return {
      startTime: '',
      endTime: '',
      weekTime: '',
      monthTime: '',
      diyTime: '',
      diyOptions: {
        disabledDate(time) {
          let curDate = (new Date()).getTime()
          let year = 365 * 24 * 3600 * 1000
          let oneYear = curDate - year
          return time.getTime() > Date.now() || time.getTime() < oneYear
        }
      },
      weekOptions: {
        firstDayOfWeek: 1,
        disabledDate(time) {
          const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
          let weekFirstDay = new Date(today - (today.getDay() - 1) * 86400000) // 本周第一天
          let weekLastDay = new Date((weekFirstDay / 1000 + 6 * 86400) * 1000) // 本周第最后一天
          if (time.getTime() >= weekFirstDay.getTime() && time.getTime() < weekLastDay.getTime()) {
            return false
          }
          let curDate = (new Date()).getTime()
          let year = 365 * 24 * 3600 * 1000
          let oneYear = curDate - year
          return (time.getTime() > Date.now() || time.getTime() < oneYear)
        }
      },
      monthOptions: {
        disabledDate(time) {
          let curDate = (new Date()).getTime()
          let year = 365 * 24 * 3600 * 1000
          let oneYear = curDate - year
          return time.getTime() > Date.now() || time.getTime() < oneYear
        }
      },
    }
  },
  mounted() {
    this.startTime = this.common.formatDate('yyyy-MM-dd', new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    this.endTime = this.common.formatDate('yyyy-MM-dd', new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0))
    this.$emit('chooseTime', this.startTime, this.endTime)
  },
  methods: {
    selectTime() {
      let type = ''
      switch (this.dateType) {
        case 0:
          type = 'diy'
          break
        case 1:
          type = 'week'
          break
        case 2:
          type = 'month'
          break
        case 3:
          type = 'date'
          break
      }
      this.$refs[type].pickerVisible = true
    },
    formatDiy(value) {
      this.startTime = value[0]
      this.endTime = value[1]
      this.$emit('chooseTime', this.startTime, this.endTime)
    },
    formatWeek(value) {
      const day = new Date(value).getTime()
      // 获取用户选择的时间当前周的第一天和最后一天
      let weekFirstDay = new Date(day - (new Date(day).getDay() - 1) * 86400000)
      let weekLastDay = new Date((weekFirstDay / 1000 + 6 * 86400) * 1000)
      this.startTime = this.common.formatDate('yyyy-MM-dd', weekFirstDay)
      this.endTime = this.common.formatDate('yyyy-MM-dd', weekLastDay)
      this.$emit('chooseTime', this.startTime, this.endTime)
    },
    formatMonth(value) {
      const valueList = value.split('-')
      this.startTime = value
      this.endTime = this.common.formatDate('yyyy-MM-dd', new Date(valueList[0], valueList[1], 0))
      this.$emit('chooseTime', this.startTime, this.endTime)
    },
  }
}
</script>

<style lang="scss" scoped>

.choose-time {
  position: relative;
}

.select-time {
  width: 240px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 3px 14px 3px 14px;
  background: #F6F6F6;
  border-radius: 0 16px 16px 0;

  .range-placeholder {
    width: 90px;
    height: 21px;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    margin-left: 5px;
    color: #C0C4CC;
    background-color: #F6F6F6;
  }

  .range {
    color: #333333;
  }

  .range-separator {
    width: 15px;
    height: 21px;
    font-size: 15px;
    font-weight: 400;
    color: #999999;
    line-height: 21px;
    margin: 0 10px;
  }
}

.visible-date {
  position: absolute;
  right: 10px;
  top: 0;
  z-index: -1;

  &.month {
  }

  &.week {
  }

  &.diy {
    right: 0;
  }
}
</style>
