<template>
  <article class="work-by-school-container"
           v-loading="loading"
           element-loading-text="正在加载中..."
           element-loading-spinner="el-icon-loading">
    <RouterNav :name="name"></RouterNav>
    <div class="workBySchool">
      <div class="column-box">
        <div class="column">
          <ul class="tabs">
            <li :class="['list', tabName === '作业列表' ? 'active' : '']"
                @click="tabChange('作业列表')">
              <div>作业列表</div>
              <div class="line"></div>
            </li>
            <li :class="['list', tabName === '作业统计' ? 'active' : '']"
                @click="tabChange('作业统计')">
              <div>作业统计</div>
              <div class="line"></div>
            </li>
          </ul>
        </div>
        <div class="column">
          <label>年级：</label>
          <div
            :class="['radio-item', gradeList[gradeIndex].gradeTypeId === item.gradeTypeId ? 'radio-item-active' : '']"
            v-for="(item, index) in gradeList"
            :key="item.id" @click="gradeChange(index)">{{ item.gradeName }}
          </div>
        </div>
        <div class="column">
          <label>班级：</label>
          <div class="column-content">
            <div :class="['radio-item', classIndex === index ? 'radio-item-active' : '']"
                 v-for="(item, index) in classDetails"
                 :key="item.classId" @click="clazzChange(index,item)">
              <template v-if="item">{{ item.className }}</template>
            </div>
          </div>
        </div>
        <div class="column">
          <label>时间：</label>
          <div class="column-content time">
            <div class="left">
              <DatePicker v-model="timeRang" :time-interval="180" width="255px" format="yyyy-MM-dd" value-format="yyyy-MM-dd" />
              <el-button class="search-btn" type="primary" plain @click="findHomeworkByClassIdOld">查询</el-button>
            </div>
            <div class="right">
              <div v-if="selection.length" class="right-btn delete" @click="batchDelete">删除所选</div>
              <div class="right-btn export" @click="batchExport">批量导出</div>
            </div>
          </div>
        </div>
      </div>
      <div class="homework-content homework-list" v-if="tabName==='作业列表'">
        <div class="homework-content__nav">
          <el-radio-group v-model="status" size="medium" @change="findHomeworkByClassIdOld">
            <el-radio-button label="">全部状态</el-radio-button>
            <el-radio-button label="1"><span class="space"/>进行中<span class="space"/></el-radio-button>
            <el-radio-button label="0"><span class="space"/>未开始<span class="space"/></el-radio-button>
            <el-radio-button label="2"><span class="space"/>已结束<span class="space"/></el-radio-button>
          </el-radio-group>
          <span>任课老师：{{ teacherName }}</span>
        </div>
        <div class="homework-content__line"></div>
        <BagList
          :list="homeworkList"
          :show-check="true"
          @exportExcel="exportExcel"
          @delWork="delWork"
          @bagWorkList="bagWorkList"
          @setWork="setWork"
          @selectionChange="selectionChange"
        />
        <div class="text-right mt-20 pr-60">
          <Pagination :page-size="pageSize" :total="total" :page="page" @size-change="handleChangePageSize" @current-change="handleChangePage" />
        </div>
        <div class="empty-homework" v-if="homeworkList.length===0">
          <div class="icon-classes-none"></div>
          <div style="margin-bottom: 10px;">暂无作业数据</div>
        </div>
      </div>
      <div class="homework-content homework-statistics" v-else-if="tabName==='作业统计'">
        <div class="empty-homework" v-if="userListLength===0">
          <div class="icon-classes-none"></div>
          <div style="margin-bottom: 10px;">当前班级还没有学生加入，暂无作业数据</div>
        </div>
        <template v-else>
          <div class="homework-content__nav">
            <el-radio-group v-model="dateType" size="medium">
              <el-radio-button :label="2">按自然月查询</el-radio-button>
              <el-radio-button :label="1">按自然周查询</el-radio-button>
              <el-radio-button :label="0">自定义时间</el-radio-button>
            </el-radio-group>
            <div class="homework-content__right">

              <ChooseTime
                :date-type="dateType"
                @chooseTime="chooseTime"
                back-ground-style="background:#FFFFFF"
                placeholder-style="background:#FFFFFF;color:#000000;text-decoration: underline;"/>

              <div v-if="classIndex !==0" class="search-btn" @click="getDataView">查询</div>
            </div>
          </div>
          <div class="homework-content__line"/>

          <div class="empty-homework" v-if="classIndex===0">
            <div class="icon-classes-none"></div>
            <div style="margin-bottom: 10px;">暂不支持线上查看全年级的作业报告，您可选择导出相应得数据在本地进行浏览</div>
          </div>


          <div class="data-container" v-show="isSearch">


            <section class="section">
              <div class="title">
                <div class="left">作业数据统计</div>
              </div>
              <div class="statistics">
                <div class="statistics-item">
                  <div class="item-title">作业总数</div>
                  <div class="item-num">{{ homeworkCount }}</div>
                </div>
                <div class="statistics-item" >
                  <div class="item-title">平均作业率
                    <el-popover
                      placement="bottom"
                      trigger="hover"
                      :open-delay="500"
                      :close-delay="500"
                      :visible-arrow="false"
                      popper-class="mark-circle-tips"
                      transition="el-zoom-in-top">
                      <slot name="content">
                        班级在选定时间段内“开始”的子作业的班级作业率平均值，反映学生在作业中的参与比率。
                      </slot>
                      <img  slot="reference" class="mark" src="../../assets/mark-3.png" alt="?">
                    </el-popover>
                  </div>
                  <div class="item-num">{{ operatingRate }}%</div>
                </div>
                <div class="statistics-item">
                  <div class="item-title">平均完成度
                    <el-popover
                      placement="bottom"
                      trigger="hover"
                      :open-delay="500"
                      :close-delay="500"
                      :visible-arrow="false"
                      popper-class="mark-circle-tips"
                      transition="el-zoom-in-top">
                      <slot name="content">
                        班级在选定时间段内“开始”的子作业的班级完成度平均值，反映学生在作业中的完成程度。
                      </slot>
                      <img  slot="reference" class="mark" src="../../assets/mark-3.png" alt="?">
                    </el-popover>
                  </div>
                  <div class="item-num">{{ finish }}%</div>
                </div>
                <div class="statistics-item">
                  <div class="item-title">平均得分率
                    <el-popover
                      placement="bottom"
                      trigger="hover"
                      :open-delay="500"
                      :close-delay="500"
                      :visible-arrow="false"
                      popper-class="mark-circle-tips"
                      transition="el-zoom-in-top">
                      <slot name="content">
                        班级在选定时间段内“开始”的子作业的班级得分率平均值，反映学生在作业中的得分情况。
                      </slot>
                      <img  slot="reference" class="mark" src="../../assets/mark-3.png" alt="?">
                    </el-popover>
                  </div>
                  <div class="item-num">{{ scoreRate }}%</div>
                </div>
              </div>
            </section>


            <section class="section" v-if="classIndex !== 0">
              <div class="title">
                <div class="left">学生完成度分布统计</div>
                <div class="right">*点击蓝色柱状图可查看对应学生名单</div>
              </div>
              <div class="content" ref="content">
                <barForReport ref="activeBar"/>
              </div>
            </section>

            <section class="section" v-if="classIndex !== 0">
              <div class="title">
                <div class="left">学生得分率分布统计</div>
                <div class="right">*点击蓝色柱状图可查看对应学生名单</div>
              </div>
              <div class="content" ref="content">
                <barForReport ref="scoreBar"/>
              </div>
            </section>

            <section class="section" v-if="classIndex !==0">
              <div class="title">
                <div class="left">班级学生数据明细</div>
                <div class="right right-operation">
                  <el-switch v-model="defaultSort" @change="switchChange"></el-switch>
                  <div class="tips">
                    默认排序
                    <el-tooltip
                      effect="dark"
                      placement="bottom-end"
                      :open-delay="500">
                      <span slot="content" class="mark-tips">默认排序规则为：按照“得分率*作业数”的值大小倒序排列。同时，可以切换到其他排序规则：按照“得分率”大小倒序排列、按照“得分率”大小正序排列、按照“完成度”大小倒序排列、按照“完成度”大小正序排列。</span>
                      <img class="mark" src="../../assets/mark-3.png" alt="?">
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <el-table
                ref="table"
                v-show="classIndex!==0"
                :data="studentList"
                border
                header-cell-class-name="table-header-cell"
                cell-class-name="table-cell"
                @sort-change="sortChange"
              >
                <el-table-column label="综合排名" prop="rank" align="center"/>
                <el-table-column label="学生姓名" prop="name" align="center"/>
                <el-table-column label="作业数" prop="homeworkCount" align="center"/>
                <el-table-column label="完成度" :sort-orders="['descending', 'ascending', null]" prop="finish" sortable sort-by="finish" align="center">
                  <template slot-scope="{row}">
                    <span>{{ row.finish }}%</span>
                  </template>
                </el-table-column>
                <el-table-column label="得分率" :sort-orders="['descending', 'ascending', null]" prop="scoreRate" sortable sort-by="scoreRate" align="center">
                  <template slot-scope="{row}">
                    <span>{{ row.scoreRate }}%</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                  <template slot-scope="{row}">
                    <el-button type="text" style="font-size: 15px" @click="userReport(row.studentId)">查看报告</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </section>

          </div>

        </template>
      </div>
      <div class="homework-statistics__footer" v-if="tabName==='作业统计'&&userListLength>0">
        <el-popover
          placement="top"
          width="351"
          trigger="manual"
          popper-class="export-tips"
          v-model="detailVisible">
          <slot name="content">
            下载任务已提交，请前往
            <router-link to="/down/center">下载中心</router-link>
            查看进度
          </slot>
          <el-button slot="reference" class="btn btn-detail" @click="exportData('detail',classIndex===0)">导出作业明细表
          </el-button>
        </el-popover>

        <el-popover
          placement="top"
          width="351"
          trigger="manual"
          popper-class="export-tips"
          v-model="statisticsVisible">
          <slot name="content">
            下载任务已提交，请前往
            <router-link to="/down/center">下载中心</router-link>
            查看进度
          </slot>
          <el-button slot="reference" class="btn btn-statistics" @click="exportData('statistics',classIndex===0)">
            导出作业统计表
          </el-button>
        </el-popover>
      </div>
    </div>
    <EditWorkSet ref="EditWorkSet" @refreshPage="refreshPage"></EditWorkSet>
  </article>
</template>

<script>

import DatePicker  from '@/components/common/DatePicker'
import RouterNav from '@/components/RouterNav.vue'
import BagList from './components/BagList.vue'
import barForReport from '@/views/report/components/barForReport'
import ChooseTime from "@/components/common/ChooseTime";
import Pagination from '@/components/common/Pagination.vue'
import {getStudents} from "@/api/classes";
import EditWorkSet  from '@/components/EditWorkSet'
import {batchDeleteHomeworkBag, exportBagByCreator, exportBagBySchool, findBagPageBySchool} from '@/api/homework.js'
import dayjs from 'dayjs'

export default {
  name: 'workBySchool',
  components: {
    RouterNav,
    barForReport,
    ChooseTime,
    BagList,
    EditWorkSet,
    DatePicker,
    Pagination
  },
  data() {
    return {
      loading: false,
      tabName: '作业列表',
      id: '',
      name: '',
      gradeList: [],
      gradeIndex: 0,
      yearList: [],
      year: null,
      month: null,
      statisticsVisible: false,
      detailVisible: false,
      startTime: '',
      endTime: '',
      homeworkCount: 0,
      finish: 0,
      scoreRate: 0,
      operatingRate: 0,
      dateType: 2,
      classDetails: [],
      classIndex: 0,
      timeRang: [],
      selection: [],
      teacherName: '',
      defaultSort: true,
      status: '',
      homeworkList: [],
      userListLength: 0,
      isSearch: false, //是否按下搜索按钮
      tempData: null, // 查询的临时参数
      studentList: [],
      page: 1,
      pageSize: 10,
      total: 0,
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  watch: {
    statisticsVisible(val){
      if (val){
        setTimeout(()=>{
          this.statisticsVisible = false
          this.detailVisible = false
        },2000)
      }
    },
    detailVisible(val){
      if (val){
        setTimeout(()=>{
          this.statisticsVisible = false
          this.detailVisible = false
        },2000)
      }
    },
    gradeList() {
      this.classListChange()
    },
    classDetails(val) {
      this.page = 1
      if (val.length === 0) {
        this.homeworkList = []
        return
      }
      this.findHomeworkByClassIdOld()
    }
  },
  created() {
    this.id = this.$route.query.id
    this.name = this.$route.query.name
    this.timeRang = [dayjs().subtract(1, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')]
    this.findClassesBySchoolId()
  },
  methods: {
    sortChange() {
      this.defaultSort = false
    },
    switchChange(flag) {
      if (flag) {
        this.$refs.table.clearSort()
        this.sortData(this.studentList)
      }
    },
    sortData(list) {
      this.studentList = list.sort((a, b) => {
        return  a.rank - b.rank
      })
    },
    initBarCharts(target, data) {
      const {offsetWidth} = this.$refs.content
      const barWidth = offsetWidth - 30
      const echarts = require('echarts')
      const option = {
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          textStyle: {
            color: 'white'
          },
          formatter: function (params) {
            const {dataIndex} = params
            return `<p>${data.name}：${params.name}</p><p>人数：${data.studentSumList[dataIndex]}人</p><p>${data.studentList[dataIndex].join()}</p>`
          },
          extraCssText: 'max-width:400px;white-space: pre-wrap'
        },
        title: {
          show: true,
          subtext: '人数占比',
          subtextStyle: {
            color: '#999',
            fontSize: 13,
            fontStyle: 'normal',
            fontWeight: 'normal'
          },
          left: '55',
          top: '15',
        },
        xAxis: {
          axisLine: {show: false},
          axisTick: {show: false},
          axisLabel: {
            interval: 0,
            textStyle: {
              fontSize: '12',
              color: '#333'
            }
          },
          show: true,
          emphasis: {
            color: '#fff'
          },
          name: data.name,
          nameTextStyle: {
            verticalAlign: "top",
            lineHeight: 20
          },
          data: data.xNameList
        },
        series: [{
          name: '人数',
          type: 'bar',
          data: data.xValueList,
          barWidth: 30,
          itemStyle: {
            normal: {
              color: '#C0E0FB',
              label: {
                show: true,
                formatter: '{c}%',
                position: 'top',
                textStyle: {
                  color: '#999999',
                  fontSize: 13
                }
              }
            }
          },
          emphasis: {
            itemStyle: {
              color: new echarts.graphic.LinearGradient(
                0, 0, 0, 1,
                [
                  {offset: 0, color: '#6DBBFC'}, // 柱图高亮渐变色
                  {offset: 1, color: '#309AF2'} // 柱图高亮渐变色
                ]
              )
            }
          }
        }]
      }
      target.initCharts({
        dataParam: option,
        width: barWidth
      })
    },
    userReport(id) {
      const {dateType} = this
      const dataParam = {
        name: '查看个人报告',
        startTime: this.startTime,
        endTime: this.endTime,
        classId: this.classDetails[this.classIndex].classId,
        id,
        dateType
      }
      this.$router.push({
        name: 'userReport',
        query: dataParam
      })
    },
    handleChangePageSize(val) {
      this.pageSize = val
      this.findHomeworkByClassIdOld()
    },
    handleChangePage(val) {
      this.page = val
      this.findHomeworkByClassIdOld()
    },
    /**
     * 获取该班级的作业列表
     */
    findHomeworkByClassIdOld() {
      this.selection = []
      const {page, pageSize, classDetails, classIndex, timeRang} = this
      const {classId, teacherName} = classDetails[classIndex]
      let clazzIds = [classId]
      if (classId === 'all') { // 当前是全部班级，无需请求
        this.userListLength = 1 // 假装有学生，否则无法查询
        if (this.tabName === '作业统计') return
        clazzIds = classDetails.filter(el => el.classId && el.classId !== 'all').map(el => el.classId)
      }
      this.teacherName = teacherName
      const dataParam = {
        teacherId: '',
        page,
        pageSize,
        clazzIds,
        startTime: '',
        endTime: ''
      }
      if (timeRang && timeRang.length) {
        dataParam.startTime = timeRang[0] ? dayjs(timeRang[0]).format('YYYY-MM-DD 00:00:00') : ''
        dataParam.endTime = timeRang[1] ? dayjs(timeRang[1]).format('YYYY-MM-DD 23:59:59') : ''
      }
      if (!dataParam.startTime) {
        this.$message.error('开始时间不能为空')
        return
      }
      const {status} = this
      if (status !== '-1') dataParam.status = status
      this.loading = true
      findBagPageBySchool(dataParam).then((response) => {
        this.loading = false
        if (response.state === '11') {
          const {data} = response
          this.total = data.total
          const {pageContents} = data
          for (let i = 0, len = pageContents.length; i < len; i += 1) {
            pageContents[i].dateStart = this.common.formatDate('yyyy-MM-dd', new Date(pageContents[i].dateStart))
          }
          this.homeworkList = pageContents
        }
        if (this.tabName === '作业统计') {
          return getStudents(classId)
        }
      }).then((res) => {
        this.userListLength = res.data?.userList?.length
        if (this.userListLength) {
          this.getDataView()
        }
      }).catch(() => {
        this.loading = false
      })
    },
    /**
     * tab 切换
     * @param val
     */
    tabChange(val) {
      this.tabName = val
      this.classListChange()
    },
    /**
     * 切换年级
     * @param {Number} index 下标
     */
    gradeChange(index) {
      this.gradeIndex = index
      this.classListChange()
    },
    /**
     * 切换班级数据
     * @param {Number} val 值
     */
    classListChange() {
      const {gradeList} = this
      const {gradeIndex} = this
      const {classDetails} = gradeList[gradeIndex]
      const classList = classDetails
      // if (this.tabName === '作业统计') { // 需要多一个全部班级
      //   if (classList[0]?.classId !== 'all') {
      //     classList.unshift({
      //       classId: "all",
      //       className: "全部班级",
      //       teacherName: "",
      //       year: "",
      //     })
      //   }
      //   this.isSearch = false
      // } else {
      //   if (classList[0]?.classId === 'all') {
      //     classList.shift()
      //   }
      // }

      if (classList[0]?.classId !== 'all') {
        classList.unshift({
          classId: "all",
          className: "全部班级",
          teacherName: "",
          year: "",
        })
      }
      this.isSearch = false

      this.classDetails = classList
      this.classIndex = 0
    },
    /**
     * 切换班级
     * @param index
     */
    clazzChange(index) {
      this.page = 1
      this.classIndex = index
      this.isSearch = false
      this.findHomeworkByClassIdOld()
    },
    chooseTime(start, end) {
      this.startTime = start
      this.endTime = end
    },
    getDataView() {
      const {dateType} = this
      let classId = this.classDetails[this.classIndex].classId;
      if (classId === 'all'){
        return
      }
      const dataParam = {
        classId: classId,
        startTime: this.startTime,
        endTime: this.endTime,
        dateType
      }
      this.loading = true
      this.$axios({
        method: 'post',
        url: this.$urls.getDataView,
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataParam
      })
      .then((response) => {
        this.loading = false
        if (response?.state === '11') {
          this.isSearch = true
          this.defaultSort = true
          const {data} = response
          this.homeworkCount = data.homeworkCount || 0
          this.scoreRate = data.scoreRate || 0
          this.operatingRate = data.operatingRate || 0
          this.finish = data.finish || 0
          this.className = data.className
          const {finishGroup, scoringGroup} = data
          const finishGroupObj = {
            name: '完成度',
            xNameList: [],
            xValueList: [],
            studentList: [],
            studentSumList: []
          }
          if (finishGroup?.length){
            for (let i = 0, len = finishGroup.length; i < len; i += 1) {
              finishGroupObj.xNameList.push(finishGroup[i]?.columns)
              finishGroupObj.xValueList.push(finishGroup[i]?.proportion || 0)
              finishGroupObj.studentList.push(finishGroup[i]?.studentList || 0)
              finishGroupObj.studentSumList.push(finishGroup[i]?.studentSum)
            }
          }
          this.finishGroupObj = finishGroupObj
          this.initBarCharts(this.$refs.activeBar, finishGroupObj)

          const scoringGroupObj = {
            name: '得分率',
            xNameList: [],
            xValueList: [],
            studentList: [],
            studentSumList: []
          }
          if (scoringGroup?.length){
            for (let i = 0, len = scoringGroup.length; i < len; i += 1) {
              scoringGroupObj.xNameList.push(scoringGroup[i]?.columns)
              scoringGroupObj.xValueList.push(scoringGroup[i]?.proportion || 0)
              scoringGroupObj.studentList.push(scoringGroup[i]?.studentList ||0)
              scoringGroupObj.studentSumList.push(scoringGroup[i]?.studentSum)
            }
          }
          this.scoringGroupObj = scoringGroupObj
          this.initBarCharts(this.$refs.scoreBar, scoringGroupObj)
          this.sortData(data.studentList || [])
        }
      })
      .catch(() => {
        this.loading = false
      })
    },
    /**
     * 导出统计数据
     */
    exportData(type, isAll) {
      const {dateType} = this
      const dataParam = {
        schoolId: this.id,
        gradeId: this.gradeList[this.gradeIndex].gradeTypeId,
        year:this.year,
        classId: this.classDetails[this.classIndex].classId,
        startTime: this.startTime,
        endTime: this.endTime,
        dateType
      }
      if (isAll) {
        dataParam.year = new Date().getFullYear()
      }
      let url = ''
      url = isAll ? type === 'detail' ? 'exportHomeWordDetail' : 'exportHomeWordStatistics' : type === 'detail' ? 'exportReportDetail' : 'exportReportStatistics'
      this.$axios({
        method: 'post',
        url: this.$urls[url],
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataParam
      })
      .then((response) => {
        if (response?.state === '11') {
          this[`${type}Visible`] = true
        }
      })
    },
    /**
     * 获取学校底下的年级年度班级信息
     */
    findClassesBySchoolId() {
      this.$axios({
        method: 'post',
        url: this.$urls.findClassesBySchoolId,
        data: {
          schoolId: this.id
        }
      }).then((response) => {
        if (response.state === '11') {
          this.$store.commit('includeAdd', 'workBySchool')
          const {data} = response
          const gradeList = []
          for (let i = 0, len = data.length; i < len; i += 1) {
            if (data[i]) {
              const {classDetails} = data[i]
              if (classDetails) {
                gradeList.push(data[i])
              }
            }
          }
          this.gradeList = gradeList.sort((a, b) => a.gradeTypeId - b.gradeTypeId)
        }
      })
    },
    /**
     * 跳转作业详情
     * @param {Number} index
     */
    bagWorkList(index) {
      const {homeworkList} = this
      const {id, name, taskType} = homeworkList[index]
      if (taskType === 3) {
        this.$confirm('该内容Web版未适配，请前往APP查看', '提示', {
          type: 'warning'
        }).then(() => {})
        return
      }
      this.$router.push({
        name: 'bagWorkList',
        query: {
          id,
          name
        }
      })
    },
    /**
     * 导出
     * @param itemId
     */
    exportExcel(itemId) {
      const that = this
      const h = this.$createElement
      let checked = false
      const random = Math.random()
      const provinceId = String(this.$route.query.provinceId)
      const section = String(this.$route.query.section)
      this.$confirm('确认导出成绩吗？', '提示', {
        message: h('div', null, [
          h('p', null, '确认导出成绩吗？'),
          (provinceId === '440000' && section === '2' ? h('ElCheckbox', {
            style: 'position: absolute;left: 6px;bottom: -30px;',
            key: random,
            props: {
              checked: checked
            },
            on: {
              change(val) {
                checked = val
              }
            }
          }, '导出转换为20分制') : '')
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // const url = `${that.$urls.exportBagScore}?bagId=${itemId}`
        // window.open(url)
        const convertScore = checked ? 1 : 0
        checked = false
        that.$axios({
          method: 'post',
          url: that.$urls.exportBagScope,
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            bagId: itemId,
            convertScore
          }
        }).then((response) => {
          if (response.state === '11') {
            that.$message.success('下载任务已提交，请前往下载中心查看进度')
          }
        }).catch(() => {
        })
      }).catch((err) => {
        checked = false
        throw err
      })
    },
    /**
     * 删除
     * @param index
     */
    delWork(index) {
      const that = this
      this.$confirm('删除后学生将无法收到该作业？', '删除作业', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.confirmDel(index)
      }).catch((err) => {
        throw err
      })
    },
    confirmDel(index) {
      const {homeworkList} = this
      const {id} = homeworkList[index]
      let url
      const dataParam = {}
      url = this.$urls.deleteHomeBag
      dataParam.bagId = id
      this.$axios({
        method: 'post',
        url,
        data: dataParam
      }).then((response) => {
        if (response.state === '11') {
          this.homeworkList.splice(index, 1)
        }
      })
    },
    // 设置作业包
    setWork(item) {
      this.$refs.EditWorkSet.dialogVisibleChange(item)
    },
    selectionChange(selection) {
      this.selection = selection
    },
    // 更新数据
    refreshPage() {
      this.findHomeworkByClassIdOld()
    },
    // 批量删除
    batchDelete() {
      const that = this
      const { userInfo, selection } = this
      this.$confirm('删除后学生将无法收到所选作业？', '删除作业', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          homeworkBagIds: selection.map(el => el.id),
          teacherId: userInfo.teacherId
        }
        that.loading = true
        batchDeleteHomeworkBag(params).then(response => {
          that.loading = false
          if (response.state === '11') {
            this.findHomeworkByClassIdOld()
          }
        })
      }).catch((err) => {
        throw err
      })
    },
    // 批量导出
    batchExport() {
      const that = this
      const { startTime, endTime, total, userInfo, timeRang, status, classDetails, classIndex} = this
      const {classId} = classDetails[classIndex]
      let clazzIds = [classId]
      if (classId === 'all') { // 当前是全部班级，无需请求
        this.userListLength = 1 // 假装有学生，否则无法查询
        if (this.tabName === '作业统计') return
        clazzIds = classDetails.filter(el => el.classId && el.classId !== 'all').map(el => el.classId)
      }
      if (total > 200) {
        this.$message({
          type: 'warning',
          message: '批量导出作业数量不能超过200个'
        })
        return
      }
      const h = this.$createElement
      let checked = false
      const random = Math.random()
      const provinceId = String(this.$route.query.provinceId)
      const section = String(this.$route.query.section)
      this.$confirm('确认批量导出作业包？', '提示', {
        message: h('div', null, [
          h('p', null, '确认导出成绩吗？'),
          (provinceId === '440000' && section === '2' ? h('ElCheckbox', {
            style: 'position: absolute;left: 6px;bottom: -30px;',
            key: random,
            props: {
              checked: checked
            },
            on: {
              change(val) {
                checked = val
              }
            }
          }, '导出转换为20分制') : '')
        ]),
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const convertScore = checked ? 1 : 0
        checked = false
        const dataParam = {
          teacherId: userInfo.teacherId,
          startTime,
          endTime,
          convertScore,
          status,
          clazzIds
        }
        if (timeRang && timeRang.length) {
          dataParam.startTime = dayjs(timeRang[0]).format('YYYY-MM-DD 00:00:00')
          dataParam.endTime = dayjs(timeRang[1]).format('YYYY-MM-DD 23:59:59')
        }
        that.loading = true
        exportBagBySchool(dataParam).then(response => {
          that.loading = false
          if (response.state === '11') {
            that.$message.success('下载任务已提交，请前往下载中心查看进度')
          }
        }).catch(() => {
          that.loading = false
        })
      }).catch((err) => {
        throw err
      })
    }
  }
}
</script>

<style lang="scss">
.el-radio-button__inner {
  font-size: 15px;
  font-weight: normal;
}

.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  color: #309AF2;
  font-weight: bold;
  background-color: #EAF4FD;
  border-color: #DCDFE6;
  -webkit-box-shadow: -1px 0 0 0 #DCDFE6;
  box-shadow: -1px 0 0 0 #DCDFE6;
}

.work-by-school-container {
  .el-date-editor--daterange {
    width: 260px;
    font-size: 15px;

    .el-range-input {
      width: 100px;
      color: #333333;
      font-size: 15px;
      border: none;
      padding: 0;
      text-decoration: underline;

      &::placeholder {
        color: #333333;
      }
    }

    //.el-range-separator {
    //  display: inline-block;
    //  width: 20px;
    //  color: #999999;
    //  font-size: 15px;
    //}
  }

  .table-title {
    font-size: 17px !important;
    font-weight: bold !important;
    padding-left: 18px;
  }

  .cell {
    color: #333333;
    font-size: 15px;
    font-weight: normal;
  }
}
</style>
<style scoped lang="scss">
//.work-by-school-container {
//  height: calc(100vh - 84px);
//  overflow: auto;
//}

.mark-tips {
  display: block;
  width: 328px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #D8D8D8;
  line-height: 20px;
}

.nav {
  height: 40px;
  line-height: 40px;
  @include font(14px, #999999);
  margin-bottom: 1px;

  .link {
    color: #999999;

    &:hover {
      color: #309AF2;
      text-decoration: underline;
    }
  }

  .icon {
    display: inline-block;
    margin: 0 6px;
  }
}

.column-box {
  background-color: #fff;
  padding: 0 28px;
}

.column {
  @include flex(flex-start);
  min-height: 54px;
  border-bottom: 1px solid #F1F1F1;
  .column-content{
  }

  .tabs {
    @include flex(flex-start, flex-end);
    height: 54px;
    padding-bottom: 5px;

    .list {
      margin-right: 28px;
      @include font(15px, #999);
      cursor: pointer;

      .line {
        width: 12px;
        height: 4px;
        border-radius: 2px;
        margin: 7px auto 0;
      }
    }

    .active {
      color: #333;
      font-weight: bold;

      .line {
        background-color: #309AF2;
      }
    }
  }

  &-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 15px;

    .radio-item {
      margin-bottom: 15px;

      &:last-child {
        margin-right: 0;
      }
    }

    &:empty::before {
      content: '无';
      color: #999999;
      font-size: 15px;
      margin-top: -15px;
    }

    &.time{
      width: 100%;
      padding-top: 0;
      @include flex(space-between);
      .left {
        @include flex;
        .search-btn {
          background: linear-gradient(to right, #6DBBFC, #309AF2);
          width: 100px;
          margin-left: 14px;
          color: #fff;
          font-size: 15px;
          border-color: transparent;
        }
        .select-status{
          width: 110px;
          height: 32px;
          margin-left: 14px;
          ::v-deep .el-input{
            .el-input__inner{
              color: #333333;
              font-size: 14px;
            }
            .el-input__suffix{
              .el-input__suffix-inner{
                .el-select__caret{
                  &::before{
                    color: #333333;
                    font-weight: 900;
                  }
                }
              }
            }
          }
        }
      }
      .right{
        @include flex;
        .right-btn{
          height: 36px;
          font-size: 15px;
          line-height: 36px;
          border: 1px solid #309AF2;
          color: #309AF2;
          border-radius: 8px;
          background: #FFFFFF;
          padding: 0 14px;
          cursor: pointer;
          &.export{
            border-color: #309AF2;
            color: #309AF2;
          }
          &.delete{
            border-color: #FF3C30;
            color: #FF3C30;
          }
          &+.right-btn{
            margin-left: 14px;
          }
        }
      }
    }
  }

  label {
    flex-shrink: 0;
    color: #333333;
    font-size: 15px;
  }

  .radio-item {
    height: 28px;
    line-height: 28px;
    border-radius: 4px;
    color: #666666;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #C8C8C8;
    background-color: #F6F6F6;
    padding: 0 14px;
    margin-right: 14px;

    &:hover {
      color: #333333;
    }
  }

  .radio-item-active {
    background-color: #EAF4FD;
    color: #309AF2;
    border-color: #309AF2;

    &:hover {
      color: #309AF2;
    }
  }
}

.homework-content {
  background-color: #fff;
  padding: 14px 28px;
  margin-top: 10px;

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      color: #309AF2;
      font-size: 15px;
    }
  }

  &__right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__line {
    height: 1px;
    background-color: #F1F1F1;
    margin-top: 14px;
  }

  .space {
    display: inline-block;
    width: 7.5px;
  }

  .search-btn {
    background: linear-gradient(to right, #6DBBFC, #309AF2);
    width: 100px;
    height: 36px;
    line-height: 36px;
    border-radius: 4px;
    @include font(17px, #fff, center);
    cursor: pointer;

    &:hover {
      background: #309AF2;
    }

    &:active {
      background: #6DBBFC;
    }
  }

  .data-container {
    margin-top: 20px;

    .section {
      background-color: #fff;
      margin-bottom: 20px;
      overflow: hidden;

      .title {
        background: #F6F6F6;
        border: 1px solid #E5E5E5;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          font-size: 17px;
          font-weight: bold;
          color: #333333;
          line-height: 24px;
          padding: 15px 0 15px 28px;
        }

        .right {
          padding-right: 28px;
          font-size: 15px;
          font-weight: 400;
          color: #999999;
          line-height: 21px;
        }

        .right-operation {
          display: flex;
          align-items: center;

          .tips {
            display: flex;
            align-items: center;
            margin-left: 15px;

            .mark {
              margin-left: 5px;
            }
          }
        }
      }

      .el-table {

        ::v-deep .table-header-cell {
          font-size: 15px;
          font-weight: bold;
          color: #333333;
          line-height: 21px;
          height: 54px;
          background: #F6F6F6;

          &:first-child {
            border-left: 1px solid #EBEEF5;
          }
        }

        ::v-deep .table-cell {
          .cell {
            font-size: 15px;
            font-weight: 400;
            color: #333333;
            line-height: 21px;
            padding: 6px 0;
          }
        }

        ::v-deep .el-table__row td:first-child {
          border-left: 1px solid #EBEEF5;
        }

        ::v-deep .el-table__body tr:hover > td {
          background-color: #F6F6F6 !important;
        }

        ::v-deep th.ascending {
          .sort-caret.ascending {
            border-bottom-color: #333333;
          }
        }

        ::v-deep th.descending {
          .sort-caret.descending {
            border-top-color: #333333;
          }
        }

      }


      .statistics {
        display: flex;
        justify-content: space-around;
        align-items: center;


        .statistics-item {
          border: 1px solid #E5E5E5;
          border-top-color: transparent;
          height: 120px;
          width: 100%;
          text-align: center;

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3), {
            border-right-color: transparent;
          }

          .item-title {
            font-size: 17px;
            font-weight: 400;
            color: #999999;
            padding-top: 21px;
            display: flex;
            justify-content: center;
            align-items: center;

            .mark {
              margin-left: 10px;
            }
          }

          .item-num {
            padding-top: 12px;
            font-size: 30px;
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
  }

}

.homework-list > .empty-homework {
  padding-top: 130px;
  padding-bottom: 130px;
}

.homework-statistics > .empty-homework {
  padding-top: 180px;
  padding-bottom: 180px;
}

.empty-homework {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  background-color: #ffffff;

  .icon-classes-none {
    width: 173px;
    height: 155px;
    background: url("../../assets/icon-classes-none.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 30px;
  }
}

.homework-statistics__footer {
  text-align: center;
  padding: 24px 28px 20px 28px;
  background-color: #FFFFFF;

  .btn {
    width: 200px;
    height: 46px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #309AF2;
  }

  .btn-detail {
    font-size: 15px;
    font-weight: 400;
    color: #309AF2;
    line-height: 21px;

    &:hover {
      background: #F6F6F6;
    }
  }

  .btn-statistics {
    background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);
    font-size: 15px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 21px;
    margin-left: 28px;

    &:hover {
      background: #309AF2;
    }
  }
}
</style>
